<template>
  <div>
    <main v-if="schoolData">
      <!-- ======= Search Section ======= -->
      <div class="web-resfound p-2">
        <button class="btn web-backbtn" v-if="isPayment == 'paidVersion'" @click="$router.go(-1)">
          <v-icon name="arrow-left" />
          <span class="bck" style="display:none">Back</span>
        </button>
        <div class="container ">
          <div class="row">
            <div class="col-md-3 text-center mt-3 imgresp">
               <img width="200" height="120" v-if="schoolData.image && schoolData.image.path"
                        :src="schoolData.image.path"
                        :alt="schoolData.accountName"
                        :title="schoolData.accountName"
                />
                <img  width="200" height="120" v-else src="../../assets/img/noimage.png" alt="Avatar">
                <!-- <img 
                  :src="schoolData.image"
                  :alt="schoolData.accountName"
                  :title="schoolData.accountName"
                /> -->
             
            </div>
            <div class="col-md-9 web-schnamecont mt-md-3 mt-sm-3">
              <h4>
                <strong
                  >{{ schoolData.accountName }} </strong
                >
              </h4>
              <p>
                <span
                  ><i
                    class="fa fa-map-marker fa-lg"
                    aria-hidden="true"
                  ></i></span
                >
                {{ schoolData.street ? schoolData.street : '' }},{{ schoolData.city ? schoolData.city : '' }},{{ schoolData.district ? schoolData.district : '' }},{{ schoolData.state ? schoolData.state : '' }},{{ schoolData.pincode ? schoolData.pincode : '' }}
              </p>
              <p>
                <span><i class="fa fa-envelope" aria-hidden="true"></i></span
                ><a>{{ schoolData.email }}</a>
              </p>
              <p>
                <span
                  ><i class="fa fa-phone-square" aria-hidden="true"></i></span
                >{{ schoolData.landlineNo ? schoolData.landlineNo : ''  }}
              </p>
              <div class="web-admbtn">
                <a
                  class="btn web-custbtn web-rounded-xl h-100 btn-3 "
                  style="width:225px"
                  >{{ checkAdmissionAuvaiable > 0 ? 'Admission Open Now' : 'Admission Closed' }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="web-filterbg pl-3 pr-3 m-0" id="filtersects">
        <div class="container-fluid">
          <div class="row">
            <div class="offset-md-3 web-mobnav">
              <nav class=" vertical-align-middle scroll ml-2">
                <ul
                  v-scroll-spy-active="{ class: 'tabactive' }"
                  v-scroll-spy-link
                >
                  <li><a>Overview</a></li>
                  <li><a>Seat Availability</a></li>
                  <li><a>Admission Process</a></li>
                  <li><a>Facilities</a></li>
                  <li><a>Contact Details</a></li>
                  <li><a>Gallery</a></li>
                  <li><a>Rating & Reviews</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="web-innerpagecont py-3 pl-3 pr-3">
        <div class="container-fluid">
          <div class="row web-row-flex">
            <!-- <div class="col-md-3 col-sm-6">
              <p><strong>Place for Google ad</strong></p>
            </div> -->
            <div class="col-md-12 col-sm-12">
              <div v-scroll-spy="{ data: 'section', offset: 120 }">
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection">
                      <img
                        src="../../assets/img/school_info_icon.svg"
                        width="99%"
                        alt="Overview"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Overview</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    
                   <p :style="overviewHight == true ? 'overflow: hidden;' : 'height: 84px;overflow: hidden;'">
                     <!-- <span v-if="schoolData.overView.length  65">  -->
                     {{ schoolData.overView }} 
                   <!-- </span> -->
                    </p>
                    
                    <a v-if="!overviewHight && schoolData.overView && schoolData.overView.length > 540" href="javascript:void(0);" style="margin-left: 39%;" @click="increaseOverViewheight">Read More</a>
                   <a v-if="overviewHight && schoolData.overView && schoolData.overView.length > 540" href="javascript:void(0);" style="margin-left: 39%;" @click="reduceOverViewheight">Read Less</a>
                  </div>
                   <!-- <a v-if="!overviewHight" href="javascript:void(0);" style="margin-left: 39%;" @click="increaseOverViewheight">Read More</a>
                   <a v-if="overviewHight" href="javascript:void(0);" style="margin-left: 39%;" @click="reduceOverViewheight">Read Less</a> -->
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection">
                      <img
                        src="../../assets/img/seatsavailicn.svg"
                        width="99%"
                        alt="Seats Availability"
                        title="Seats Availability"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Seats Availability</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <table>
                      <thead>
                        <tr>
                          <th scope="col" style="text-align:center">Class Details</th>
                          <th scope="col" style="text-align:center">No. of Seats</th>
                          <th scope="col" style="text-align:center">No. of Seats Available</th>
                          <th scope="col" style="text-align:center">Admission Status</th>
                          <th scope="col" style="text-align:center">Annual Fees</th>
                          <th scope="col" style="text-align:center">Admission Fees</th>
                          <th scope="col" style="text-align:center">Online</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr  v-for="(fee, index) in admissionDetails" :key="index">
                          <td data-label="Class Details" style="text-align:center">{{ fee.class ? fee.class.className : '' }}</td>
                          <td data-label="No. of Seats" style="text-align:center">{{ fee.noOfSeats }}</td>
                          <td data-label="No. of Seats" style="text-align:center">{{ fee.seatsAvailable }}</td>
                          <td data-label="No. of Seats" style="text-align:center">{{ fee.admissionStatus }}</td>
                          <td data-label="Total Fees" style="text-align:center">{{ fee.amount }} </td>
                          <td data-label="Total Fees" style="text-align:center">{{ fee.admissionFees || '-' }} </td>
                          <td data-label="Apply Now" style="text-align:center">
                             <a  href="javascript:void();" class="web-applynow" v-if="fee.amount && fee.admissionStatus == 'Open' " @click.prevent="applyAdmissionOnline(index)">
                              <img 
                                style="background-color: yellowgreen;border-radius: 30px;"
                                src="../../assets/img/handcursoricn.svg"
                                
                                alt="apply now"
                                title="Apply Now"
                              />
                            </a>
                             <a  href="javascript:void();" class="web-notapply" v-else >
                             <img 
                                style="cursor: not-allowed;border-radius: 30px;"
                                src="../../assets/img/notapply.svg"
                               
                                alt="Admission Closed"
                                title="Admission Closed"
                              />
                            </a>
                             
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:6px">
                      <img
                        src="../../assets/img/admissionicn.svg"
                        width="99%"
                        alt="Vidhyaan Admission Process"
                        title="Vidhyaan Admission Process"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Admission Process</h1>
                    </div>
                  </div>

                  <div class="nottble p-3 dispflex" style="justify-content: normal">
                    
               <div class="chkbox" data-title="Select" style="width: 20%;">
                  <label class="custcheckbox">
                    <input type="checkbox" disabled v-model="schoolData.isOnlineAdmission" />
                    <span class="checkmarkchk" ></span>
                    
                  </label>
                  <label style="margin-left:35px">Online Admission</label>
                </div>

                <div class=" chkbox" data-title="Select" style="width: 20%;">
                  <label class="custcheckbox">
                    <input type="checkbox" disabled v-model="schoolData.isOfflineAdmission"/>
                    <span class="checkmarkchk"></span>
                    
                  </label>
                  <label style="margin-left:35px">Offline Admission</label>
                </div>
              </div>

                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:6px">
                      <img
                        src="../../assets/img/facilitiesicn.svg"
                        width="99%"
                        alt="Facilities"
                        title="Facilities"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Facilities</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <p>
                      The school facility consists of not just the physical
                      structure such as plumbing, mechanical, electrical,
                      telecommunications, security, fire prevention and
                      suppression systems, and the various building systems, but
                      it also includes furnishing, materials and supplies,
                      equipment and information technology. Basic facilities we
                      have in our schools like,
                    </p>
                    <div class="web-dispflex">
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/school_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Type of School</p>
                          <span>{{ schoolData.schoolType }}</span>
                        </span>
                      </div>
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/afflilation_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Examination Board</p>
                          <span>{{ schoolData.affilliateIdToBoard.toString()}}</span>
                        </span>
                      </div>
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/fees_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Annual Fees</p>
                          <span
                            >Start from Rs. {{ schoolData.lowestFees }}</span
                          >
                        </span>
                      </div>
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/lang_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Language of Instruction</p>
                          <span>{{ schoolData.medium.toString() }}</span>
                        </span>
                      </div>
                      <div
                        class="web-colwidth mb-4 colcent"
                        v-for="(fitem, index) in schoolData.facilities"
                        :key="index"
                      >
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            :src="`${facilitiesURL}/${fitem.icon}.svg`"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>{{ fitem.name }}</p>
                          <span>Yes</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:10px">
                      <img
                        src="../../assets/img/contactdetailsicon.svg"
                        width="99%"
                        alt="Contact Details"
                        title="Contact Details"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Contact Details</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <div class="form-row">
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="schoolData.email">
                        <span class="web-has-float-label" style="flex-grow:0">
                          <label for="email">Email</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          <a href="mailto:info@velammal.org"
                            >{{ schoolData.email }}</a
                          >
                        </div>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3" v-if="schoolData.landlineNo">
                        <span class="web-has-float-label" style="margin:1px">
                          <label for="phno">Phone</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          +91 {{ schoolData.landlineNo }}
                        </div>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="schoolData.website">
                        <span class="web-has-float-label" style="flex-grow:0">
                          <label for="website">Website</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          <a v-if="schoolData.website"
                            href="http://www.velammal.org/velammal-vidhyashram-mambakkam/"
                            target="_blank"
                            >{{ schoolData.website }}</a
                          >
                        </div>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3" v-if="schoolData.location">
                        <span class="web-has-float-label" style="margin:1px">
                          <label for="location">Location</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          {{ schoolData.location }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:10px">
                      <img
                        src="../../assets/img/reviewicn.svg"
                        width="99%"
                        alt="Gallery"
                        title="Gallery"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Gallery</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <div class="web-dispflex">
                     
                      <div style="margin-right:10px;"
                        
                        v-for="(item, index) in galleryList"
                        :key="index"
                      >
                        <div class="galcontainer">
                          <img style="width:150px;height:150px;"
                            :src="item.path"
                           
                            alt="Avatar"
                            class="mt-3 galimage"
                          />
                        
                        </div>
                     
                      </div>
                    </div>
                   
                    
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:10px">
                      <img
                        src="../../assets/img/reviewicn.svg"
                        width="99%"
                        alt="Ratings & Reviews"
                        title="Ratings & Reviews"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Ratings & Reviews</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <div class="web-dispflex rating">
                      <div class="web-colwidth">
                        <p><strong>Review by Parents</strong></p>
                        <hr />
                        <div class="web-dispflex">
                          <div>
                            <p>
                              <label>School Infrastructure: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="true"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                :rating="parentReview.infrastructure"
                                class="vi-r-review"
                              />
                            </p>
                             <p>
                              <label>School Management: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                :rating="parentReview.management"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                           
                            <p>
                              <label>Extra Curricular: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                :rating="parentReview.sports"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Lab Facilities: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                :rating="parentReview.facilities"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Safety: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                :rating="parentReview.safety"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                             <p>
                              <label>Faculty: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                :rating="parentReview.faculty"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            
                            
                          </div>
                          <div class="web-overall">
                            <p>{{ parentReview | averageRating }}</p>
                            <p>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.5"
                                :rating="parentReview | averageRating"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>(Over All) </p>
                          </div>
                        </div>
                      </div>
                      <div class="web-colwidth">
                        <p><strong>Review by Vidhyaan</strong></p>
                        <hr />
                        <div class="web-dispflex">
                          <div>
                            <p>
                              <label>School Infrastructure: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                
                                :rating="adminReview.infrastructure"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                             <p>
                              <label>School Management: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                :rating="adminReview.management"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                           
                            <p>
                              <label>Extra Curricular: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                :rating="adminReview.sports"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Lab Facilities: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                :rating="adminReview.facilities"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Safety: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                :rating="adminReview.safety"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                             <p>
                              <label>Faculty: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="1"
                                :rating="adminReview.faculty"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            
                            
                          </div>
                          <div class="web-overall">
                            <p>{{ adminReview | averageRating }}</p>
                            <p>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :rating="adminReview | averageRating"
                                :increment="0.5"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>(Over All)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="web-dispflex mt-3" v-if="parentReviewList.length > 0">
                      <div class="web-colwidth rating" style="border:0">
                        <h3>Write a Review</h3>
                        <div class="widjetcontent">
                <div class="kidswrapper p-3">
                  <div class="row">
                    <div class="col-12" v-for="(data, index) in parentReviewList" :key="index" >
                      <div class="kids-details-box mb-3" v-if="index == 0 || index == 1 || index == 2">
                        <div
                          class="has-float-label"
                          
                        >
                          <div class="schlogo">
                            
                             <div class="white-box" >
                              <div class="no-blu no-cirlce">
                                            {{ data.parent ? data.parent.firstName.charAt(0) : "" }}{{ data.parent ? data.parent.lastName.charAt(0) : "" }}
                              </div>
                             </div>
                            
                            
                         
                          </div>
                        </div>
                        
                        <div class="item-content">
                          <table class="table text-nowrap">
                            <tbody>
                              <tr>
                                <td>Name:</td>
                                <td >
                                 {{ data.parent.firstName }}
                                </td>
                               
                              </tr>
                              <tr>
                                <td>Date:</td>
                                <td >
                                 {{ data.updatedDate | dateFormat }}
                                </td>
                               
                              </tr>
                              <tr>
                                <td>Ratings:</td>
                                <td >
                                  <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :rating="data.overAllRating"
                                :increment="0.5"
                                :read-only="true"
                                class="vi-r-review"
                              />
                                </td>
                               
                              </tr>
                             
                              
                             
                              <tr>
                                <td>Comments</td>
                                <td>{{ data.comment }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <button style="width: 37%;margin-left: 88%;"
            type="button"
            class="btn btnsml"
            id="svbtn"
             v-b-modal.reviewPopUp
            v-if="parentReviewList.length > 3"
          >
              <span>More Reviews</span>
          </button>
                </div>
              </div>
                      </div>
                     
                    </div>
              </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- <vi-spinner
      v-else
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="width: 100%;z-index: 111;top:0;left:0;background-color: #fff;bottom:0;right:0;"
    /> -->
    <b-modal id="addstudpop" class="modal" no-close-on-backdrop no-close-on-esc>
      <div class="contact-form">
        <h2>Online Admissssssion{{ contactDetails.eligibilityCriteria }}</h2>
        <br />
        <div >
          <form data-vv-scope="studentvalidate" id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
               <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    disabled
                    name="studentFirstName"
                    v-model="contactDetails.className"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                
                  <label for="stdFirstName" 
                    >Class Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    v-validate="'required'"
                    name="studentFirstName"
                    v-model="contactDetails.firstName"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentFirstName')"
                    class="vi-error"
                    style="top:-1em"
                    >First name is required</label
                  >
                  <label for="stdFirstName" v-else
                    >First Name <span class="required">*</span></label
                  >
                </span>
              </div>

              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="text"
                    name="studentLastName"
                    v-model="contactDetails.lastName"
                    class="form-control form-input"
                    id="stdLastName"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentLastName')"
                    class="vi-error"
                    style="top:-1em"
                    >Last name is required</label
                  >
                  <label for="stdLastName" v-else
                    >Last Name<span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="stdEmail"
                    v-model="contactDetails.email"
                    class="form-control form-input"
                    id="stdEmail"
                  />
                  <label for="stdEmail"
                    >Secondary Email</label
                  >
                </span>
              </div>
              <!-- <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <select
                    name="status"
                    disabled
                    class="form-control form-input"
                    id="enrollmentStatus"
                    v-model="contactDetails.admissionStatus"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in enrollmentStatusList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                   
                 
                  <label for="enrollmentStatus" 
                    >Enrollment Status <span class="required">*</span></label
                  >
                </span>
              </div> -->
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <!-- max="9999-12-31" -->
                <span class="has-float-label">
                  <input
                   v-validate="'required'"
                    type="date"
                    onkeydown="return false"
                    name="DOB"
                    v-model="contactDetails.dob"
                    class="form-control form-input"
                    id="stdDob"
                  />
                  <label
                    v-if="errors.first('studentvalidate.DOB')"
                    class="vi-error"
                    style="top:-1em"
                    >Date of birth is required</label
                  >
                 
                 
                  <label for="stdDob" v-else
                    >Date of Birth <span class="required">*</span></label
                  >
                </span>
              </div>
              
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    v-validate="'required'"
                    name="Blood Group"
                    class="form-control form-input"
                    id="bloodGroup"
                    v-model="contactDetails.bloodGroup"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(blood, index) in bloodGroupList"
                      :key="index"
                      :value="blood"
                    >
                      {{ blood }}
                    </option>
                  </select>
                  <label
                    v-if="errors.first('studentvalidate.Blood Group')"
                    class="vi-error"
                    style="top:-1em"
                    >Blood group is required</label
                  >
                  <label for="bloodGroup" v-else
                    >Blood Group <span class="required">*</span></label
                  >
                </span>
              </div>
              <!-- <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >Siblings?</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      type="checkbox"
                      id="sibtogBtn"
                      v-model="contactDetails.siblings"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
              </div> -->

                  <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="gender"
                            class="form-control form-input"
                            id="gender"
                            v-model="contactDetails.gender"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in genderList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidate.gender')"
                            class="vi-error"
                            style="top:-1em"
                            >Gender is required</label
                          > 
                          <label for="firstName" v-else
                            >Gender <span class="required">*</span></label
                          >
                        </span>
                      </div>
              <fieldset class="accordion-group-container">
                <div >
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-1"
                    class="blutitle accordion-label"
                  >
                    Parent Details
                  </label>
                    <div class="form-row">
                       <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="pointOfContact"
                            class="form-control form-input"
                            id="pointOfContact"
                            v-model="contactDetails.pointOfContact"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in pointOfList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidate.pointOfContact')"
                            class="vi-error"
                            style="top:-1em"
                            >Point of contact is required</label
                          > 
                          <label for="firstName" v-else
                            >Point of contact <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label" v-if="contactDetails.pointOfContact == 'Father'">
                          <input
                            type="text"
                            name="fthrname"
                            disabled
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father Name"
                          />
                          <label for="fthrnameid" 
                            >Father Name <span class="required">*</span></label
                          >
                        </span>
                         <span class="has-float-label" v-if="contactDetails.pointOfContact == 'Mother'">
                          <input
                            type="text"
                            name="Mother"
                            disabled
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                            id="Mother"
                            placeholder="Mother Name"
                          />
                          <label for="fthrnameid" 
                            >Mother Name <span class="required">*</span></label
                          >
                        </span>
                         <span class="has-float-label" v-if="contactDetails.pointOfContact == 'Guardian'">
                          <input
                            type="text"
                            name="Guardian"
                            disabled
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                            id="Guardian"
                            placeholder="Guardian Name"
                          />
                          <label for="Guardian" 
                            >Guardian Name <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            disabled
                            v-model="userDetails.occupation"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Occupation"
                          />
                          <label for="occid"
                            >Occupation </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            disabled
                            v-model="userDetails.religion"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid"
                            >Religion </label
                          >
                        </span>
                        
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            disabled
                            name="nationality"
                            v-model="userDetails.nationality"
                            class="form-control form-input"
                            id="natid"
                            placeholder="Nationality"
                          />
                          <label for="natid"
                            >Nationality </label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="email"
                            disabled
                            name="ptemail"
                            v-model="userDetails.email"
                            class="form-control form-input"
                            id="ptemailid"
                            placeholder="Email"
                          />
                          <label for="ptemailid" 
                            >Primary Email <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            maxlength="10"
                            disabled
                            name="ptph"
                            v-model="userDetails.phoneNo"
                            class="form-control form-input"
                            id="ptphid"
                            placeholder="Phone"
                          />
                         
                          <label  for="ptphid">Phone <span class="required">*</span></label>
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="stadr"
                            disabled
                            v-model="userDetails.address"
                            class="form-control form-input"
                            id="stadrid"
                            placeholder="Address Details"
                          />
                          <label for="stadrid"
                            >Address Details</label
                          >
                        </span>
                      </div>
                    </div>
                </div>

              </fieldset>
            </div>
            <div
              class="dispflex mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon">
               
              </div> -->
              <div class="text-center dk_iconsml">
                
               
                 <button 
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="submitApplication"
                >
                    <span>Save</span>
                </button>
                <button type="button" @click.prevent="closePoup" class="btn btncl clsmdl" id="clbtn">
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <b-modal id="reviewPopUp" class="modal" no-close-on-backdrop no-close-on-esc>
      <div class="contact-form">
        <h2>Reviews </h2>
        <br />
        <div style="height: 350px; overflow-y: auto">
          <form data-vv-scope="studentvalidate" id="stuprofile" accept-charset="utf-8">
            <div class="row" v-for="(data, index) in parentReviewList" :key="index">
              <div class="col-2">
                <div class="white-box" >
                              <div class="no-blu no-cirlce">
                                            {{ data.parent ? data.parent.firstName.charAt(0) : "" }}{{ data.parent ? data.parent.lastName.charAt(0) : "" }}
                              </div>
                               </div>
                </div>
                <div class="col-10">
                   <table class="table text-nowrap">
                            <tbody>
                              <tr>
                                <td style="width: 11%;">Name:</td>
                                <td >
                                 {{ data.parent.firstName }}
                                </td>
                               
                              </tr>
                              <tr>
                                <td>Date:</td>
                                <td >
                                 {{ data.updatedDate | dateFormat }}
                                </td>
                               
                              </tr>
                              <tr>
                                <td>Ratings:</td>
                                <td >
                                  <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :rating="data.overAllRating"
                                :clearable="false"
                                :increment="0.5"
                                :read-only="true"
                                class="vi-r-review"
                              />
                                </td>
                               
                              </tr>
                              
                             
                              <tr>
                                <td>Comments</td>
                                <td>{{ data.comment }}</td>
                              </tr>
                            </tbody>
                          </table>
                </div>
              
                    <!-- <div class="col-12" v-for="(data, index) in parentReviewList" :key="index" >
                      <div class="kids-details-box mb-3" v-if="index == 0 || index == 1 || index == 2">
                        <div
                          class="has-float-label"
                          
                        >
                          <div class="schlogo">
                            
                             <div class="white-box" >
                              <div class="no-blu no-cirlce">
                                            {{ data.parent ? data.parent.firstName.charAt(0) : "" }}{{ data.parent ? data.parent.lastName.charAt(0) : "" }}
                              </div>
                             </div>
                            
                            
                         
                          </div>
                        </div>
                        
                        <div class="item-content">
                          <table class="table text-nowrap">
                            <tbody>
                              <tr>
                                <td>Name:</td>
                                <td >
                                 {{ data.parent.firstName }}
                                </td>
                               
                              </tr>
                              <tr>
                                <td>Date:</td>
                                <td >
                                 {{ data.updatedDate | dateFormat }}
                                </td>
                               
                              </tr>
                              
                             
                              <tr>
                                <td>Comments</td>
                                <td>{{ data.comment }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div> -->
                    
                  </div>
            <div
              class="dispflex mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <div class="dk_icon">
               
              </div>
              <div class="text-right dk_iconsml">
                
               
                
                <button type="button" @click.prevent="closeReviewPoup" class="btn btncl clsmdl" id="clbtn">
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </div>
  
</template>


<script>
import "vue-awesome/icons/arrow-left";
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "../../utils/secureUI";
import StarRating from "vue-star-rating";
export default {
  name: "vi-school-overview",
  components: {
    ViSpinner,
    StarRating,
  },
  props: ["swapComponent"],
  data() {
    return {
      overviewHight: false,
      isPayment: '',  
      section: 0,
      viLoader: true,
      schoolData: null,
      admissionSelect: 'aOn',
      infrastructure: null,
      academics: null,
      sports: null,
      faculty: null,
      safety: null,
      message: null,
      messageError: null,
      rateError: null,
      classList: [],
      academicYearList: [],
      galleryList: [],
      bloodGroupList: ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"],
      facilitiesURL: process.env.BASE_URL + "facilities",
      admissionDetails: [],
      contactDetails: {
        firstName: "",
        lastName: "",
        admissionType: "online",
        dob: null,
        doj: null,
        dateOfLeaving: null,
        pointOfContact: 'Father',
        academicYear: null,
        gender: "Male",
        parentId: null,
        accountId: null,
        email: "",
        mobileOne: 0,
        mobileTwo: 0,
        image: "",
        addressOne: "",
        addressTwo: "",
        state: "",
        district: "",
        postalCode: "",
        country: "",
        classApplied: "",
        bloodGroup: null,
        admissionStatus: 'Submitted',
        siblings: false,
        rollNoAllocated: "",
        class: null,
        classSection: null,
        parentId: null,
        siblingName: null,
        siblingRollNo: "",
        siblingDiscount: '',
        className: '',
        admissionDeadline: null,
        eligibilityCriteria: []
      },

      userDetails: {
        firstName: "",
        lastName: "",
        fatherName: "",
        motherName: "",
        email: "",
        phoneNo: null,
        password: "",
        role: "",
        membership: "Free",
        userId: "",
        gender: "",
        occupation: "",
        religion: "",
        nationality: "",
        address: "",
        registeredAs: "",
        userDevices: [],
        profile: null,
      },
      parentReviewList: [],
      adminReview: {
            infrastructure: 0,
            sports: 0,
            facilities: 0,
            management: 0,
            faculty: 0,
            safety: 0
      },
      parentReview: {
            infrastructure: 0,
            sports: 0,
            facilities: 0,
            management: 0,
            faculty: 0,
            safety: 0
      },
      enrollmentStatusList: [
        "Active",
        "Inactive",
        "Submit", 
        "In Review",
        "Assessment",
        "Approved", 
        "Reject", 
        "Enrolled"
      ],
      pointOfList: [
       'Father',
       'Mother',
       'Guardian'
      ],
      genderList: [
       'Male',
       'Female'
      ]
    };
  },
  computed: {
   
     checkAdmissionAuvaiable() {
      if(this.admissionDetails.length > 0){
        
       const statusCheck = this.admissionDetails.filter( x => x.admissionStatus == 'Open' && x.isShowSearch)
       return statusCheck.length
      }else{
        return 0
      }
      
    }
  },
  created() {
	  this.initmethod()

  },
  mounted(){
    window.addEventListener("scroll", this.onScroll)
  },
  methods: {
    increaseOverViewheight(){
      this.overviewHight = true
    },
     reduceOverViewheight(){
      this.overviewHight = false
    },

    async initmethod(){
      await this.getSchoolInfo();
    },
    onScroll(){
      let adfiltsect = document.getElementById("filtersects");
  
  

    if (adfiltsect) {
      if (
        document.body.scrollTop > 70 ||
        document.documentElement.scrollTop > 70
      ) {
        adfiltsect.classList.add("fixed-top");
        adfiltsect.style.top = "0px";
        adfiltsect.style.zIndex = "12";
      } else {
        adfiltsect.classList.remove("fixed-top");
      }
    }
    },
    async getSchoolInfo() {
    
      ViService.viGetOnly(`/common/details/schoolNameAndAccoutId?accountId=${this.$route.query.accId}&name=${this.$route.query.schoolName}`)
        .then((res) => {
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);
            if (res.data) {
              this.schoolData = data;
              if(this.schoolData){
                //this.schoolData.overView = 'Techparrot Innovations Llp is a Limited Liability Partnership firm incorporated on 18 May 2021. It is registered at Registrar of Companies, Chennai. Its total obligation Techparrot Innovations Llp is a Limited Liability Partnership firm incorporated on 18 May 2021. It is registered at Registrar of Companies, Chennai. Its total obligation Techparrot Innovations Llp is a Limited Liability Partnership firm incorporated on 18 May 2021. It is registered at Registrar of Companies, Chennai. Its total obligation of contribution is Rs. 10,000. Designated Partners of Techparrot Innovations Llp are Vimal Das Adiyeri Cainadath and Juliet Lathika Rajeesh.'
                 this.searchSchoolDetails();
                 this.schoolGallaryList()
                 this.getRatingDetails()
              }
            }
          } else {
            this.$toasted.show(res.message);
          }
        })
        .catch((e) => {
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
        });
    },
    setRating(rating, value) {
      value == "infrastructure" ? (this.infrastructure = rating) : null;
      value == "academics" ? (this.academics = rating) : null;
      value == "sports" ? (this.sports = rating) : null;
      value == "faculty" ? (this.faculty = rating) : null;
      value == "safety" ? (this.safety = rating) : null;
    },
    submitRating() {
      let userData = secureUI.sessionGet("user");
      if (!userData) {
        this.$toasted.error("Please login and do the action");
         this.$router.push('/login');
      } else {
        this.$toasted.clear();
     
        if (
          !this.infrastructure ||
          !this.academics ||
          !this.sports ||
          !this.faculty ||
          !this.safety
        ) {
          this.rateError = true;
        } else {
          this.rateError = null;
        }
        if (
          !message &&
          this.infrastructure &&
          this.academics &&
          this.sports &&
          this.faculty &&
          this.safety
        ) {
          let requestBody = {
            accountId: this.schoolData._id,
            parentId: userData.userDetails._id,
            infrastructure: this.infrastructure,
            academics: this.academics,
            sports: this.sports,
            faculty: this.faculty,
            safety: this.safety,
            message: this.message,
            schoolName: "",
            category: "",
            city: "",
          };
          
          this.viLoader = true;
          ViService.viXPost("/rating/school", requestBody, userData.token)
            .then((res) => {
              this.viLoader = null;
              if (res.isSuccess) {
                this.clearMsg();
                this.$toasted.success("Rating submitted successfully");
              } else {
                this.$toasted.error(res.message);
              }
            })
            .catch((e) => {
              this.viLoader = null;
              let eData = errorLog.apiErrorLog(e);
              this.$toasted.error(eData);
            });
        }
      }
    },
     async schoolGallaryList() {
       if(this.schoolData){
       const response = await ViService.viGetOnly("/common/galleryList?accountId=" + this.schoolData._id);
      // const response = await ViService.viXGet(`/account/galleryList?accountId=${userData.userDetails.account._id}`,userData.token);


        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data); 
          this.galleryList = details.gallery || []
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
     }
    },
     async getRatingDetails() {
     
       if(this.schoolData){
       const response = await ViService.viGetOnly("/common/overAllRating?accountId=" + this.schoolData._id);


        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data); 
          if(details.length > 0){
            const adminReviewDetails = details.filter( x => x.isReviewedByAdmin)
            if(adminReviewDetails.length > 0){
              this.adminReview.infrastructure = adminReviewDetails[0].infrastructure,
              this.adminReview.sports = adminReviewDetails[0].sports,
              this.adminReview.facilities = adminReviewDetails[0].facilities,
              this.adminReview.management = adminReviewDetails[0].management,
              this.adminReview.faculty = adminReviewDetails[0].faculty,
              this.adminReview.safety = adminReviewDetails[0].safety
            }
            const parentReviewDetails = details.filter( x => x.isReviewedByParent && x.status == "Approved")
            if(parentReviewDetails.length > 0){
            this.parentReviewList = parentReviewDetails

            const infrastructureTotal = parentReviewDetails.map(o => o.infrastructure).reduce((a, c) => { return a + c });
            const infrastructureTotalSum = (infrastructureTotal / parentReviewDetails.length).toFixed(2)
            this.parentReview.infrastructure = parseFloat(infrastructureTotalSum)

            const sportTotal = parentReviewDetails.map(o => o.sports).reduce((a, c) => { return a + c });
            const sportTotalSum = (sportTotal / parentReviewDetails.length).toFixed(2)
            this.parentReview.sports = parseFloat(sportTotalSum)

            const facilitiesTotal = parentReviewDetails.map(o => o.facilities).reduce((a, c) => { return a + c });
            const facilitiesTotalSum = (facilitiesTotal / parentReviewDetails.length).toFixed(2)
            this.parentReview.facilities = parseFloat(facilitiesTotalSum)

            const managementTotal = parentReviewDetails.map(o => o.management).reduce((a, c) => { return a + c });
            const managementTotalSum = (managementTotal / parentReviewDetails.length).toFixed(2)
            this.parentReview.management = parseFloat(managementTotalSum)

            const facultyTotal = parentReviewDetails.map(o => o.faculty).reduce((a, c) => { return a + c });
            const facultyTotalSum = (facultyTotal / parentReviewDetails.length).toFixed(2)
            this.parentReview.faculty = parseFloat(facultyTotalSum)

            const safetyTotal = parentReviewDetails.map(o => o.safety).reduce((a, c) => { return a + c });
            const safetyTotalSum = (safetyTotal / parentReviewDetails.length).toFixed(2)
            this.parentReview.safety = parseFloat(safetyTotalSum)
            }

          }
          
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
     }
    },
    
    async submitApplication(){
      let isFormValid = false;

       await this.$validator.validateAll('studentvalidate').then(result => {
          isFormValid = result;
       });

      if (isFormValid) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
      
       ViService.viXPost("/contact/student/parentPortal/apply",this.contactDetails, userData.token)
            .then((res) => {
              if (res.isSuccess) {
                this.$toasted.success(res.message);
                this.$router.push("/parent/admission");
                
              } else {
                this.$toasted.error(res.message);
              }
            })
            .catch((e) => {
              let eData = errorLog.apiErrorLog(e);
              this.$toasted.error(eData);
            });
      }
      }
    },
    applyAdmissionOnline(index){
        let userData = secureUI.sessionGet("user");
        
        if (!userData) {
          this.$toasted.error("Sign-Up/Login to the Vidhyaan Portal to Apply for Admission");
          this.$router.push('/login');
        } else {

          if(userData.userDetails.profile && userData.userDetails.profile.name == 'Parent'){

              const userinfromation  = userData.userDetails
              this.userDetails.firstName = userinfromation.firstName,
              this.userDetails.email = userinfromation.email || '',
              this.userDetails.phoneNo = userinfromation.phoneNo || '',
              this.userDetails.occupation = userinfromation.occupation || '',
              this.userDetails.religion = userinfromation.religion || '',
              this.userDetails.nationality = userinfromation.nationality || '',
              this.userDetails.address = userinfromation.address || ''
              this.contactDetails.parentId = userinfromation._id || ''

              const classDetails = this.admissionDetails[index]
              console.log("classDetails",classDetails)
              this.contactDetails.parentId = userinfromation._id || ''
              this.contactDetails.className = classDetails.class ? classDetails.class.className : ''
              this.contactDetails.class = classDetails.class ? classDetails.class._id : ''
              this.contactDetails.academicYear = classDetails.academicYear
              this.contactDetails.accountId = this.schoolData._id
              this.contactDetails.admissionDeadline = classDetails.admissionDeadline
              this.contactDetails.eligibilityCriteria = classDetails.eligibilityCriteria

              this.$bvModal.show("addstudpop");

          }else{
            this.$toasted.error('Access restricted for school admin');
          }
       
        }
    },
    closePoup(){
     this.$bvModal.hide("addstudpop");
     this.clearForm();
    },
    closeReviewPoup(){
     this.$bvModal.hide("reviewPopUp");
    },
    
    clearForm(){

        this.contactDetails.firstName = "";
        this.contactDetails.lastName = "";
        this.contactDetails.admissionType = "online";
        this.contactDetails.dob = null;
        this.contactDetails.academicYear = null;
        this.contactDetails.gender = "Male";
        this.contactDetails.parentId = null;
        this.contactDetails.accountId = null;
        this.contactDetails.email = "";
        this.contactDetails.mobileOne = 0;
        this.contactDetails.mobileTwo = 0;
        this.contactDetails.image = "";
        this.contactDetails.addressOne = "";
        this.contactDetails.addressTwo = "";
        this.contactDetails.state = "";
        this.contactDetails.district = "";
        this.contactDetails.postalCode = "";
        this.contactDetails.country = "";
        this.contactDetails.classApplied = "";
        this.contactDetails.bloodGroup = null;
        this.contactDetails.admissionStatus = 'Active';
        this.contactDetails.siblings = false;
        this.contactDetails.rollNoAllocated = "";
        this.contactDetails.class = null;
        this.contactDetails.classSection = "";
        this.contactDetails.siblingName = "";
        this.contactDetails.siblingRollNo = "";
        this.contactDetails.siblingDiscount = '';
        this.contactDetails.eligibilityCriteria = [];
        this.userDetails.firstName = "";
        this.userDetails.lastName = "";
        this.userDetails.fatherName = "";
        this.userDetails.motherName = "";
        this.userDetails.email = "";
        this.userDetails.phoneNo = null;
        this.userDetails.password = "";
        this.userDetails.role = "";
        this.userDetails.membership = "Free";
        this.userDetails.userId = "";
        this.userDetails.gender = "";
        this.userDetails.occupation = "";
        this.userDetails.religion = "";
        this.userDetails.nationality = "";
        this.userDetails.address = "";
        this.userDetails.registeredAs = "";
        this.userDetails.userDevices = [];
        this.userDetails.profile = null,
        this.userId = null,
        this.editId = null,
        this.feesNotPaid = false
    },
    clearMsg() {
      this.infrastructure = 0;
      this.academics = 0;
      this.sports = 0;
      this.faculty = 0;
      this.safety = 0;
      this.message = null;
    },
    searchSchoolDetails(){
        if(this.schoolData){
       ViService.viGetOnly("/common/getSearchSchoolFeesDetail?accountId=" + this.schoolData._id)
        .then((res) => {
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);
            if (res.data) {
             this.admissionDetails = data;
            }
          } else {
            this.$toasted.show(res.message);
          }
        })
        .catch((e) => {
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
        });
        }
    },
  },
  filters: {
    averageRating: function(value) {
      if (!value) return "";
      let totalSum = 0;
      for (let key in value) {
        if (value.hasOwnProperty(key)) {
          totalSum += value[key];
        }
      }
      let val = (totalSum / 6).toFixed(1);
      return parseFloat(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.vi-r-review,
.vi-w-review {
  display: inline-block;
}
// #concludeActiveAcademicYearPopup .modal-content {
//     width: 37% !important; /* Could be more or less, depending on screen size */
	
// }

</style>

